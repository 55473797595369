import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { getDefaultLocale, isArrayLength } from '../../util/genericHelpers';
import css from './ListingPage.module.css';
import IconEducation from '../../components/IconEducation/IconEducation';
import IconExperience from '../../components/IconExperience/IconExperience';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function SectionEducationAndExperience(props) {
  const { intl, publicData, currentLocaleFromStorage } = props;
  const [numPages, setNumPages] = useState(null);
  const [resumetoShow, setResumetoShow] = useState('');

  const resume = currentLocaleFromStorage === 'en' ? publicData?.en?.resume : publicData?.resume;
  const showResume = isArrayLength(
    currentLocaleFromStorage === 'en' ? publicData?.en?.showResume : publicData?.showResume
  );
  const education = isArrayLength(
    currentLocaleFromStorage === 'en' ? publicData?.en?.education : publicData?.education
  )
    ? currentLocaleFromStorage === 'en'
      ? publicData?.en?.education
      : publicData?.education
    : null;
  const experience = isArrayLength(
    currentLocaleFromStorage === 'en' ? publicData?.en?.experience : publicData?.experience
  )
    ? currentLocaleFromStorage === 'en'
      ? publicData?.en?.experience
      : publicData?.experience
    : null;

  useEffect(() => {
    setResumetoShow(resume);
  }, [resume]);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const educationTitle = intl.formatMessage({ id: 'ListingPage.educationTitle' });

  const experienceTitle = intl.formatMessage({ id: 'ListingPage.experienceTitle' });

  const fromTitle = intl.formatMessage({ id: 'ListingPage.fromTitle' });
  const inTitle = intl.formatMessage({ id: 'ListingPage.inTitle' });

  const renderEducationExperience = (
    <div className={css.educationSec}>
      {isArrayLength(education) ? <h2>{educationTitle}</h2> : null}
      {education?.map((e, i) => (
        <div className={css.educationBlock} key={i}>
          <div className={css.educationIcon}>
            <IconEducation />
          </div>
          <div className={css.educationDetails}>
            <h3>
              {e?.grade} {fromTitle} {e?.name}
            </h3>
            <p>
              {e?.start} {fromTitle} {e?.end}
            </p>
            <p>{e?.description}.</p>
          </div>
        </div>
      ))}
      {isArrayLength(experience) ? <h2>{experienceTitle}</h2> : null}
      {experience?.map((e, i) => (
        <div className={css.educationBlock} key={i}>
          <div className={css.educationIcon}>
            <IconExperience />
          </div>
          <div className={css.educationDetails}>
            <h3>
              {e?.title} {inTitle} {e?.organization}
            </h3>
            <p>
              {e?.start} {fromTitle} {e?.end}
            </p>
            <p>{e?.description}.</p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      {renderEducationExperience}
      {showResume ? (
        <div className={css.pdfContainer}>
          <h2>{intl.formatMessage({ id: 'ListingPage.resumeTitle' })}</h2>
          <Document key={resumetoShow} file={resumetoShow} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      ) : null}
    </>
  );
}

export default SectionEducationAndExperience;
